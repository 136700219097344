import React, {Component} from "react"
import Slider from "react-slick"
import Layout from "../components/layout.en"
import style from "./h.module.scss"
import "slick-carousel/slick/slick.css"
import {GetList} from "../hooks/helicopterList"
import Img from "gatsby-image"

import Leonardo from "../images/leonardo/logo_leonardo.svg"
import img1 from "../images/leonardo/foto_leonardo.jpg"

import ImgCover from "../images/leonardo/foto_portada.jpg"
import rotationImage from "../images/leonardo/AW109E 360.png"
import Header from "../components/header";
import imgPage from "../images/headers/Logos_Ale.svg";

export default () => {
    const helicoptersList = GetList()
    return (
        <Layout clase={'aeroplane'}>

            <Header LogoPage={imgPage}/>
            <img src={ImgCover} className={style.cover} alt="Aeronaves"/>

            <div className={style.infoHeli}>
                <div className={style.right}>
                    <Leonardo/>
                    <p>En aerolíneas Ejecutivas somos representantes exclusivos en México para Leonardo Helicopters.</p>
                    <p>La división de Helicópteros Leonardo realiza investigación, diseño, desarrollo, producción, atención al cliente y comercialización de la amplia gama de helicópterps modernos de la compañia para aplicaciones comerciales, de servicio público y de seguridad y defensa. La produccion actual abarca todas las principales categorías de peso del helicóptero, desde el monomotor de 1,8 toneladas hasta el motor de 16 toneladas.</p>
                </div>
                <div className={style.left}>
                    <img src={img1} alt="Leonardo"/>
                </div>
            </div>

            <div className={style.heliList}>
                <h2>Seleccione el tipo de helicóptero</h2>
                <HeliSlider helicopters={helicoptersList}/>
            </div>

            <img src={rotationImage} className={style.cover} alt="Citation"/>

        </Layout>
    )
}


class HeliSlider extends Component {
    render() {
        const helicoptersList = this.props.helicopters;
        const settings = {
            dots: false,
            infinite: true,
            arrows: true,
            speed: 500,
            slidesToShow: 4,
            className: style.heliSlider,
            slidesToScroll: 1
        };
        return (
            <Slider {...settings}>
                {helicoptersList.map((item) => {
                    return (
                        <div className={`${style.item}`} key={item.node.frontmatter.slug}>
                            <Img fluid={item.node.frontmatter.thumbnail.childImageSharp.fluid}/>
                            <a rel="noopener noreferrer" target='_blank' href={item.node.frontmatter.slug}>{item.node.frontmatter.title}</a>
                        </div>
                    )
                })}
                {helicoptersList.map((item) => {
                    return (
                        <div className={`${style.item}`} key={item.node.frontmatter.slug}>
                            <Img fluid={item.node.frontmatter.thumbnail.childImageSharp.fluid}/>
                            <a rel="noopener noreferrer" target='_blank' href={item.node.frontmatter.slug}>{item.node.frontmatter.title}</a>
                        </div>
                    )
                })}
                {helicoptersList.map((item) => {
                    return (
                        <div className={`${style.item}`} key={item.node.frontmatter.slug}>
                            <Img fluid={item.node.frontmatter.thumbnail.childImageSharp.fluid}/>
                            <a rel="noopener noreferrer" target='_blank' href={item.node.frontmatter.slug}>{item.node.frontmatter.title}</a>
                        </div>
                    )
                })}
                {helicoptersList.map((item) => {
                    return (
                        <div className={`${style.item}`} key={item.node.frontmatter.slug}>
                            <Img fluid={item.node.frontmatter.thumbnail.childImageSharp.fluid}/>
                            <a rel="noopener noreferrer" target='_blank' href={item.node.frontmatter.slug}>{item.node.frontmatter.title}</a>
                        </div>
                    )
                })}
                {helicoptersList.map((item) => {
                    return (
                        <div className={`${style.item}`} key={item.node.frontmatter.slug}>
                            <Img fluid={item.node.frontmatter.thumbnail.childImageSharp.fluid}/>
                            <a rel="noopener noreferrer" target='_blank' href={item.node.frontmatter.slug}>{item.node.frontmatter.title}</a>
                        </div>
                    )
                })}

            </Slider>
        );
    }
}

